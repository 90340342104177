import React from 'react';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element: Component }) => {

  const isAuthenticated = Cookies.get('is_authenticated');

  if (!isAuthenticated) {
      window.location.href = '/api/microsoft/auth-redirect/';
      return null; // Return null to avoid rendering anything while redirecting
  }

  return <Component />;
};


export default ProtectedRoute;